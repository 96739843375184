"use client";
import Image from "next/image";
import React, { useState } from "react";
import pc from "../public/any.webp";
import { motion } from "framer-motion";
import Navbar from "./navbar";
import { useTranslations } from "next-intl";
import { usePathname } from "next/navigation";
import Nav from "./nav";
import { Button } from "./ui/button";
import Link from "next/link";
import { ArrowLeft, ArrowRight } from "lucide-react";

function Hero() {
  const t = useTranslations("Index");
  const pathname = usePathname();
  const lang = pathname.includes("ar") ? "ar" : "en";

  const backgroundVariants = {
    initial: {
      x: 0,
      y: 0,
    },
    animate: {
      x: [-20, -45, -20, 0, -20],
      y: [0, 20, 60, 20, 0],
      transition: {
        delay: 10,
        duration: 5,
        ease: "linear",
        repeat: Infinity,
      },
    },
  };

  const [isVisible, setIsVisible] = useState(false);

  const handleAnimationComplete = () => {
    setIsVisible(true);
  };

  return (
    <>
      <Nav />
      <div className="font-joystix relative min-h-[100vh] overflow-hidden  bg-no-repeat bg-home bg-cover bg-center px-[20px]	lg:px-[150px] pb-4 sm:pt-[180px] pt-[150px]">
        <div className="flex flex-wrap justify-between">
          <div
            className={`w-[350px]  text-center mx-auto md:mx-0 ${
              lang === "ar" ? "md:text-right " : "md:text-left "
            }  mb-10 md:mb-0`}
          >
            <motion.h3
              transition={{ duration: 4 }}
              animate={{ x: 0, opacity: 1, scale: 1, y: 0 }}
              initial={{
                x: lang == "en" ? 500 : -500,
                opacity: 0,
                scale: 0,
                y: 250,
              }}
              className="text-2xl sm:text-4xl font-normal mb-[30px] bg-gradient-to-t from-[#FE0000] to-[#FEFE04] inline-block text-transparent bg-clip-text animate-stretch"
            >
              HTML, CSS
            </motion.h3>
            <motion.h3
              transition={{ duration: 4 }}
              animate={{ x: 0, opacity: 1, scale: 1, y: 0 }}
              initial={{
                x: lang == "en" ? 500 : -500,
                opacity: 0,
                scale: 0,
                y: 200,
              }}
              className="text-2xl sm:text-4xl font-normal mb-[30px] bg-gradient-to-t from-[#FE0000] to-[#FEFE04] inline-block text-transparent bg-clip-text animate-stretch"
            >
              Java Script
            </motion.h3>
            <motion.h3
              transition={{ duration: 4, delay: 10 }}
              animate={{ x: 0, opacity: 1, scale: 1, y: 0 }}
              initial={{
                x: lang == "en" ? 500 : -500,
                opacity: 0,
                scale: 0,
                y: 150,
              }}
              className="text-2xl sm:text-4xl font-normal bg-gradient-to-t from-[#FE0000] to-[#FEFE04] inline-block text-transparent bg-clip-text animate-stretch"
            >
              Web Design
            </motion.h3>
          </div>
          <motion.div
            className="flex relative justify-center md:justify-end w-full md:w-[50%] h-[300px]"
            transition={{ duration: 4 }}
            animate={{ x: 0, opacity: 1, scale: 1, y: 0 }}
            initial={{
              x: lang == "en" ? -500 : 500,
              opacity: 0,
              scale: 0,
              y: 150,
            }}
          >
            <Image
              width={300}
              height={300}
              src={pc}
              alt="pc"
              className="max-w-full h-full absolute "
              style={{
                maxWidth: "100%",
                height: "auto",
              }}
            />

            <motion.img
              src={`/dog.webp`}
              alt="dog"
              animate={{
                opacity: 0,
                transition: {
                  duration: 0.1,
                  delay: 10,
                },
              }}
              onAnimationComplete={handleAnimationComplete}
              className={
                lang == "en"
                  ? "md:maw-w-[90px] max-w-[70px] h-[70px] md:h-[90px] top-9 md:top-7 ml-6 md:-ml-4 md:right-24 absolute"
                  : "md:maw-w-[90px] max-w-[70px] h-[70px] md:h-[90px] top-10 md:top-7 ml-6 md:left-24  absolute"
              }
            />
            <motion.img
              alt="logo"
              initial="initial"
              animate="animate"
              variants={backgroundVariants}
              src={"/logo.webp"}
              style={{
                opacity: isVisible ? 1 : 0,
              }}
              className={
                lang == "en"
                  ? "md:maw-w-[40px] max-w-[35px] h-[20px] md:h-[20px] top-9 md:top-10 md:right-24 ml-16 absolute"
                  : "md:maw-w-[40px] max-w-[35px] h-[20px] md:h-[20px] top-9 md:top-10 md:left-40 md:ml-1 ml-16 absolute"
              }
            />
          </motion.div>
        </div>

        <motion.h1
          initial={{ opacity: 0, scale: 0, y: -120 }}
          animate={{ opacity: 1, scale: 1, y: 0 }}
          transition={{ duration: 4 }}
          whileHover={{
            y: -80,
            transition: { duration: 3 },
          }}
          className="text-white lg:text-3xl mt-[50px] text-xl text-center "
          style={{
            textShadow:
              "4px 4px 2px rgba(161, 161, 161, 0.6), 10px 14px 8px rgba(0, 0, 0, 0.5)",
            transform: "perspective(500px) rotateX(15deg) rotateY(10deg)",
          }}
        >
          {t("we_dont_design_old_fashoned_solutions")}
        </motion.h1>

        <motion.h2
          transition={{ duration: 3 }}
          initial={{ y: 0 }}
          whileHover={{
            y: -80,
          }}
          className="text-white lg:text-base text-sm mt-[50px] text-center"
          style={{
            textShadow:
              "4px 4px 2px rgba(161, 161, 161, 0.6), 10px 14px 8px rgba(0, 0, 0, 0.5)",
            transform: "perspective(500px) rotateX(15deg) rotateY(10deg)",
          }}
        >
          {t("scrol_to_find_the_new_modern_solutions")}
        </motion.h2>
        <Button
          className={`rounded-full bg-white text-blue-800 py-6 font-montserrat font-semibold px-6 hover:bg-white gap-2 flex justify-center items-center mx-auto my-10`}
        >
          <Link href={`/${lang}/contact-us`} className="text-blue-800">
            {t("git")}
          </Link>
          {lang == "ar" ? <ArrowLeft size={16} /> : <ArrowRight size={16} />}
        </Button>
      </div>
    </>
  );
}

export default Hero;
