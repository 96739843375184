"use client";
import Image from "next/image";
import React, { useState } from "react";
import c1 from "../public/hrmob.png";
import c2 from "../public/hrwide.png";
import c3 from "../public/wdemodamob.png";
import c4 from "../public/wdemodawide.png";
import c5 from "../public/bayutmob.png";
import c6 from "../public/bayutWide.png";
import c7 from "../public/glanourmob.webp";
import c8 from "../public/glanourwide.webp";
import c9 from "../public/glanour1mob.webp";
import c10 from "../public/glanour1wide.webp";
import {
  ArrowLeft,
  ArrowRight,
  CircleArrowLeft,
  CircleArrowRight,
  CircleChevronLeft,
  CircleChevronRight,
} from "lucide-react";
import { useTranslations } from "next-intl";
import { usePathname } from "next/navigation";
import { motion } from "framer-motion";
import Link from "next/link";
import { Button } from "./ui/button";

function Customize() {
  const t = useTranslations("Index");
  const pathname = usePathname();
  const lang = pathname.includes("ar") ? "ar" : "en";
  const [postionIndexes, setPostionIndexes] = useState([0, 1, 2, 3, 4]);
  const handleNext = () => {
    setPostionIndexes((prevIndexes) =>
      prevIndexes.map((prevIndex) => (prevIndex + 1) % 5)
    );
  };
  const handlePrev = () => {
    setPostionIndexes((prevIndexes) =>
      prevIndexes.map((prevIndex) => (prevIndex - 1 + 5) % 5)
    );
  };

  const positions = ["center", "left1", "left", "right", "right1"];
  const images = [
    { img1: c1, img2: c2 },
    { img1: c3, img2: c4 },
    { img1: c5, img2: c6 },
    { img1: c7, img2: c8 },
    { img1: c9, img2: c10 },
  ];

  const imageVariants = {
    center: { x: "0%", scale: 1, zIndex: 4 },
    left1: { x: "-50%", scale: 0.7, zIndex: 3 },
    left: { x: "-90%", scale: 0.5, zIndex: 2 },
    right: { x: "90%", scale: 0.5, zIndex: 2 },
    right1: { x: "50%", scale: 0.7, zIndex: 3 },
  };

  return (
    <section id="marketplace">
      <div className="bg-white py-24 md:px-36 px-4 font-montserrat">
        <div>
          <h4 className="text-slate-950 text-2xl font-medium text-center mb-5">
            {t("our_ready_templates")}
          </h4>
          <h2 className="text-slate-950 text-[40px] font-bold text-center">
            {t("customized_4u")}
          </h2>
          <div className="flex items-center justify-center md:h-[450px] lg:h-[550px] h-[200px] w-full relative">
            {images.map((img, index) => (
              <motion.div
                key={index}
                className="absolute flex gap-8 lg:h-96 h-[300px]"
                initial="center"
                animate={positions[postionIndexes[index]]}
                variants={imageVariants}
                transition={{ duration: 0.5 }}
              >
                <Image
                  alt="Image 1"
                  src={img.img1}
                  className="max-w-[200px] w-[50px] h-[110px] md:w-[200px] lg:h-[350px] bg-white mx-auto object-contain mb-6 shadow-lg rounded-lg md:h-[250px]"
                />

                <Image
                  alt="Image 2"
                  src={img.img2}
                  className="max-w-[700px] w-[250px] h-[110px] md:w-[500px] lg:w-[700px] lg:h-[350px]  bg-white mx-auto object-contain mb-6 shadow-lg rounded-lg md:h-[250px]"
                />
              </motion.div>
            ))}
          </div>
          <div
            dir="ltr"
            className="flex justify-center mt-4 md:mt-8 gap-x-5 items-center w-full"
          >
            <CircleChevronLeft
              onClick={handlePrev}
              color="#004AAD"
              className="cursor-pointer"
              size={40}
            />
            <Button
              className={`capitalize hover:from-blue-800 hover:to-blue-500 rounded-full bg-gradient-to-b from-blue-800 to-blue-500 flex justify-center items-center m-0`}
            >
              <Link href={`/${lang}/contact-us`}>{t("discover_more")}</Link>
            </Button>
            <CircleChevronRight
              onClick={handleNext}
              color="#004AAD"
              className="cursor-pointer"
              size={40}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Customize;
