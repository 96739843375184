import React from "react";
import { CircleArrowRight } from "lucide-react";
import { useTranslations } from "next-intl";
import { motion } from "framer-motion";

function OurProducts() {
  const t = useTranslations("Index");
  const v = {
    initial: { opacity: 1 },
    hover: {
      opacity: 0.8,
      backgroundColor: "rgba(0,0,0,1)",
      backdropFilter: "blur(50px)",
    },
  };
  const hoverVariants = {
    initial: { opacity: 0 },
    hover: { opacity: 1, color: "#fff", backgroundColor: "rgba(1,1,1,0.7)" },
  };
  return (
    <div className="py-24 md:px-36 px-4 font-montserrat">
      <h2 className="text-slate-950 text-[40px] font-bold text-center mb-5">
        {t("our_products")}
      </h2>
      <p className="text-slate-950 opacity-70 text-xl font-normal text-center sm:px-12 px-0 mb-16">
        {t("our_products_desc")}
      </p>
      <div className="flex justify-between flex-wrap gap-y-4">
        <motion.div
          whileHover="hover"
          initial="initial"
          variants={v}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1.5 }}
          className="bg-[url('../public/mint_eco.webp')] bg-center bg-contain bg-no-repeat bg-slate-700 bg-blend-lighten relative w-full lg:w-[49.5%] p-x-[30px] pb-5 sm:pt-[400px] pt-[200px] rounded-xl flex justify-between px-9 items-center"
        >
          <h3 className="text-white sm:text-[32px] text-[22px] font-bold">
            {t("mint_eco_system")}
          </h3>
          <motion.div
            className="absolute flex items-center justify-center h-full left-0 top-1/2 -translate-y-1/2 text-white text-[20px] text-center font-bold"
            variants={hoverVariants}
            transition={{ duration: 1 }}
          >
            <p>{t("mint_eco_system_desc")}</p>
          </motion.div>
          <CircleArrowRight color="white" className="cursor-pointer" />
        </motion.div>
        <motion.div
          whileHover="hover"
          initial="initial"
          variants={v}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1.5 }}
          className="bg-[url('../public/mdo.webp')] bg-center bg-contain bg-no-repeat bg-slate-700  relative w-full lg:w-[49.5%] p-x-[30px] pb-5 sm:pt-[400px] pt-[200px] rounded-xl flex justify-between px-9 items-center"
        >
          <h3 className="text-white sm:text-[32px] text-[22px] font-bold">
            {t("MDO")}
          </h3>
          <motion.div
            className="absolute flex items-center justify-center h-full left-0 top-1/2 -translate-y-1/2 text-white text-[20px] text-center font-bold px-5"
            variants={hoverVariants}
            transition={{ duration: 1 }}
          >
            <p>{t("MDO_desc")}</p>
          </motion.div>
          <CircleArrowRight color="white" className="cursor-pointer" />
        </motion.div>
      </div>
    </div>
  );
}

export default OurProducts;
