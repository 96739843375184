"use client";
import React, { useEffect, useState } from "react";
import p1 from "../public/3.webp";
import p2 from "../public/4.webp";

import Image from "next/image";
import { CircleChevronLeft, CircleChevronRight } from "lucide-react";
import { useTranslations } from "next-intl";
import { usePathname } from "next/navigation";
import { motion } from "framer-motion";

import { Button } from "./ui/button";
import { Dialog, DialogContent, DialogTrigger } from "./ui/dialog";

function Problems() {
  const t = useTranslations("Index");
  const pathname = usePathname();
  const lang = pathname.includes("ar") ? "ar" : "en";

  const [postionIndexes, setPostionIndexes] = useState([0, 1]);
  const handleNext = () => {
    setPostionIndexes((prevIndexes): any => {
      const updateIndexes = prevIndexes.map((prevIndex) => (prevIndex + 1) % 2);
      return updateIndexes;
    });
  };
  const postions = ["center", "left"];
  const items = [
    {
      title: "kabseeh",
      subhead: "kabseeh_subhead_problem",
      problems: ["kabseeh_1proplem", "kabseeh_2proplem"],
      subhead2: "kabseeh_subhead_solve",
      solves: ["kabseeh_1sol", "kabseeh_2sol", "kabseeh_3sol"],
      footer: "kabseeh_footer",
      img: p1,
    },
    {
      title: "coustyle",
      subhead: "coustyle_subhead_problem",
      problems: ["coustyle_1problem", "coustyle_2problem"],
      subhead2: "coustyle_subhead_solve",
      solves: ["coustyle_1solve", "coustyle_2solve", "coustyle_3solve"],
      footer: "coustyle_footer",
      img: p2,
    },
  ];

  const imageVariants = {
    center: {
      x: "0%",
      scale: 1,
      zIndex: 6,
    },
    left: { x: "-50%", scale: 0.8, zIndex: 1 },
  };
  return (
    <div className="py-24 md:px-24 px-4 font-montserrat">
      <h2 className="max-w-[700px] mx-auto text-slate-950 sm:text-5xl text-3xl font-bold mb-10 text-center">
        {t("how_we_solved")}
      </h2>
      <div className="mb-10 flex flex-col items-center">
        <h3 className="text-slate-950 opacity-90 sm:text-xl text-lg px-6 max-w-[900px] font-normal text-center mx-auto mb-4">
          {t("how_we_solved_subhead")}
        </h3>

        <ul className="list-disc list-inside text-slate-950 opacity-70 sm:text-lg text-base font-normal  mb-4">
          <li className="mx-0"> {t("how_we_solved_1sol")}</li>
          <li> {t("how_we_solved_1sol")}</li>
          <li> {t("how_we_solved_2sol")}</li>
          <li> {t("how_we_solved_3sol")}</li>
          <li> {t("how_we_solved_4sol")}</li>
        </ul>
        <p className="text-slate-950 opacity-90 sm:text-2xl text-lg px-6 mt-6 font-normal text-center mx-auto mb-4">
          {t("how_we_solved_footer")}
        </p>
      </div>

      <div className="flex items-center justify-center md:h-[500px] h-[500px] w-full">
        {items.map((item, index) => (
          <motion.div
            key={index}
            className={`absolute lg:w-[600px] w-[300px] p-6 md:p-10 bg-white rounded-[20px] shadow ${
              lang === "ar" ? "ml-5 sm:ml-[30px]" : "mr-5 sm:mr-[30px]"
            } `}
            animate={postions[postionIndexes[index]]}
            variants={imageVariants}
            transition={{ duration: 0.5 }}
          >
            <Image
              src={item.img}
              alt="p1"
              style={{
                maxWidth: "300px",
                height: "250px",
              }}
              className="w-full mx-auto mb-6"
            />

            <h3 className="text-black text-xl font-semibold mt-6 mb-4">
              {t(`${item.title}`)}
            </h3>
            <p className="text-slate-950 truncate opacity-70 mb-6 text-md font-normal">
              {t(`${item.subhead}`)}
            </p>
            <Dialog>
              <DialogTrigger asChild>
                <Button
                  className={`mt-2 capitalize rounded-full bg-gradient-to-b from-blue-800 to-blue-500 flex justify-center items-center hover:from-blue-800 hover:to-blue-500 ${
                    lang == "ar" ? "mr-auto " : "ml-auto"
                  } `}
                >
                  {t("show_more")}
                </Button>
              </DialogTrigger>
              <DialogContent className="">
                <div className="p-4 ">
                  <h2 className="font-bold mb-2">{t(`${item.subhead}`)}</h2>
                  <ul className="list-disc list-inside">
                    {item.problems.map((el, index) => (
                      <li key={index}>{t(`${el}`)}</li>
                    ))}
                  </ul>
                  <h2 className="font-bold my-2">{t(`${item.subhead2}`)}</h2>
                  <ul className="list-disc list-inside">
                    {item.solves.map((el, index) => (
                      <li key={index}>{t(`${el}`)}</li>
                    ))}
                  </ul>
                </div>
              </DialogContent>
            </Dialog>
          </motion.div>
        ))}
      </div>
      <div dir="ltr" className="flex justify-center mt-32 gap-x-5">
        <CircleChevronLeft
          onClick={() => handleNext()}
          color="#004AAD"
          className="cursor-pointer"
          size={40}
        />
        <CircleChevronRight
          onClick={() => handleNext()}
          color="#004AAD"
          className="cursor-pointer"
          size={40}
        />
      </div>
    </div>
  );
}

export default Problems;
