import React, { useState } from "react";
import { Button } from "./ui/button";
import rocket from "../public/rocket.webp";
import blob from "../public/Blob 11.webp";
import Image from "next/image";
import { useTranslations } from "next-intl";
import { usePathname, useRouter } from "next/navigation";
import { motion, useMotionValue, useTransform } from "framer-motion";
import Link from "next/link";
import { ArrowLeft, ArrowRight } from "lucide-react";

function Box() {
  const t = useTranslations("Index");
  const pathname = usePathname();
  const lang = pathname.includes("ar") ? "ar" : "en";
  const mouseX = useMotionValue(0);
  const mouseY = useMotionValue(0);
  const router = useRouter();

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const x = (event.clientX - rect.left - rect.width / 2) / 2;
    const y = (event.clientY - rect.top - rect.height / 2) / 2;
    mouseX.set(x);
    mouseY.set(y);
  };

  const handleMouseLeave = () => {
    mouseX.set(0);
    mouseY.set(0);
  };

  const xPos = useTransform(mouseX, [-200, 200], [-40, 40]);
  const yPos = useTransform(mouseY, [-200, 200], [-40, 40]);

  return (
    <div
      className={`${
        lang === "ar"
          ? "md:pr-36 pr-4 pl-4 md:pl-36 lg:pl-0"
          : "md:pl-36 pl-4 pr-4 md:pr-36 lg:pr-0"
      }  my-60 mb-0 bg-black font-montserrat`}
    >
      <div className="flex flex-wrap">
        <div className="w-full lg:w-[45%] py-16 lg:order-first order-1">
          <h2
            className={`text-white text-[40px] sm:text-[60px] lg:text-[70px] xlg:text-[80px] font-semibold mb-10 text-center ${
              lang === "ar" ? "lg:text-right" : "lg:text-left"
            }`}
          >
            {t("out_of_the_box")}
          </h2>
          <motion.div
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            style={{ x: xPos, y: yPos }}
          >
            {/* <Button
              onClick={() => router.push(`${lang}/aboutus`)}
              className="capitalize hover:from-blue-800 hover:to-blue-500 rounded-full bg-gradient-to-b from-blue-800 to-blue-500 flex justify-center items-center sm:w-44 w-43 h-10 shadow border border-white ml-[50%] -translate-x-1/2"
            >
              {t("discover_more")}
            </Button> */}
            <Button
              className={`capitalize hover:from-blue-800 hover:to-blue-500 rounded-full bg-gradient-to-b from-blue-800 to-blue-500 flex justify-center items-center ${
                lang == "ar" ? "mr-auto ml-10" : "ml-auto mr-10"
              }`}
            >
              <Link href={`/${lang}/contact-us`}>{t("discover_more")}</Link>
              {lang == "ar" ? (
                <ArrowLeft size={16} />
              ) : (
                <ArrowRight size={16} />
              )}
            </Button>
          </motion.div>
        </div>
        <div className="lg:w-[55%] w-full relative">
          <Image src={blob} alt="Box" className="h-full max-w-full lg:w-full" />
          <Image
            src={rocket}
            alt="Rocket"
            className="w-full xl:w-3/4 absolute bottom-0 xl:left-20"
            style={{
              maxWidth: "100%",
              height: "auto",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Box;
