import Image from "next/image";
import React from "react";
import Quest from "./ui/quest";
import { useTranslations } from "next-intl";
import { useMotionValue, useTransform } from "framer-motion";

function Question() {
  const t = useTranslations("Index");
  const mouseX = useMotionValue(0);
  const mouseY = useMotionValue(0);

  const xPos = useTransform(mouseX, [-200, 200], [-40, 40]);
  const yPos = useTransform(mouseY, [-200, 200], [-40, 40]);
  return (
    <div className="py-24 md:px-12 px-4 font-montserrat">
      <div className="mx-auto text-center max-w-[657px]">
        <h1 className="font-bold lg:text-[64px] md:text-5xl text-4xl capitalize">
          {t("u_have_quests")}
        </h1>
        <p className="flex font-bold text-blue-900 mx-auto items-center justify-center pt-4 pb-10 lg:text-[56px] md:text-4xl text-2xl italic">
          <span>{t("we_have")}</span>
          <Image
            width={500}
            height={500}
            alt="chat"
            src={"/chats 1.svg"}
            className="lg:w-24 md:w-16 w-10 mx-4"
          />
          <span>{t("answers")}</span>
        </p>
      </div>
      <div>
        <div className="flex items- md:flex-nowrap flex-wrap bg-gradient-to-r from-slate-400 from-70% to-white to-20% bg-top bg-repeat-x bg-[length:12px_1px]">
          <div className="md:w-1/2 w-full flex  pt-10">
            <p className="italic text-3xl md:text-start text-center w-full">
              {t("All_ur_answers")}
            </p>
          </div>
          <div className="md:w-1/2 w-full md:bg-gradient-to-b from-slate-400 from-70% to-white to-20% bg-left bg-repeat-y bg-[length:1px_12px] ">
            <div className="w-full pt-12 pb-0 ">
              <div className="px-4">
                <Quest
                  className=" bg-gradient-to-r from-slate-400 from-70% to-white to-20% bg-bottom bg-repeat-x bg-[length:20px_1px] pb-4 mb-6"
                  question={t("quest_one")}
                  answer={t("ans_one")}
                />
                <Quest
                  className=" bg-gradient-to-r from-slate-400 from-70% to-white to-20% bg-bottom bg-repeat-x bg-[length:20px_1px] pb-4 mb-6"
                  question={t("quest_two")}
                  answer={t("ans_two")}
                />
                <Quest
                  className=" bg-gradient-to-r from-slate-400 from-70% to-white to-20% bg-bottom bg-repeat-x bg-[length:20px_1px] pb-4 mb-6"
                  question={t("quest_three")}
                  answer={t("ans_three")}
                />
                <Quest
                  className=" bg-gradient-to-r from-slate-400 from-70% to-white to-20% bg-bottom bg-repeat-x bg-[length:20px_1px] pb-4 mb-6"
                  question={t("quest_four")}
                  answer={t("ans_four")}
                />
                <Quest
                  className=" bg-gradient-to-r from-slate-400 from-70% to-white to-20% bg-bottom bg-repeat-x bg-[length:20px_1px] pb-4 mb-6 "
                  question={t("quest_five")}
                  answer={t("ans_five")}
                />
                <Quest
                  question={t("quest_six")}
                  answer={t("ans_six")}
                  className="pb-4 mb-4"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="h-1 bg-gradient-to-r from-slate-400 from-70% to-white to-20% bg-bottom bg-repeat-x bg-[length:12px_1px] w-full"></div>
      </div>
    </div>
  );
}

export default Question;
