import React, { useState } from "react";
import { Plus, Minus } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";

interface QuestProps {
    question ?: string;
    answer ?: string;
    className ?: string;
  }
  
  const Quest: React.FC<QuestProps> = ({className, question = "", answer = "" }) =>  {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div className={className}>
      <div className={`flex justify-between items-center`}>
        <h3 onClick={()=>setOpen(!open)} className={`${open ? "text-blue-800" :"text-slate-950"}  sm:text-lg text-base font-semibold w-[95%] cursor-pointer`}>
         {question}
        </h3>
        <AnimatePresence>
          {!open ? (
            <motion.span
              key="plus"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0 }}
              className="w-[5%]"
              onClick={() => setOpen(true)}
            >
              <Plus className="cursor-pointer"/>
            </motion.span>
          ) : (
            <motion.span
              key="minus"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0 }}
              className="w-[5%]"
              onClick={() => setOpen(false)}
            >
              <Minus color="#004AAD" className="cursor-pointer"/>
            </motion.span>
          )}
        </AnimatePresence>
      </div>
      <AnimatePresence>
        {open && (
          <motion.p
            key="content"
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
            className="text-slate-950 opacity-70 text-base font-normal w-[85%] mt-3"
          >
            {answer}
          </motion.p>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Quest;
