import React from "react";
import n1 from "../public/n1.jpg";
import n2 from "../public/n2.webp";
import n3 from "../public/n3.webp";
import Image from "next/image";
import { ChevronRight } from "lucide-react";
import { useTranslations } from "next-intl";
import { motion } from "framer-motion";
import { usePathname, useRouter } from "next/navigation";

function New() {
  const t = useTranslations("Index");
  const pathname = usePathname();
  const lang = pathname.includes("ar") ? "ar" : "en";
  const router = useRouter();
  const newData = [
    {
      image: n1,
      title: "new-1-title",
      description: "new-1-description",
    },
  ];
  return (
    <div className="py-24 md:px-36 px-4 font-montserrat">
      {lang === "ar" && (
        <h2
          dir="ltr"
          className="text-slate-950 sm:text-5xl text-3xl font-bold text-center mb-8"
        >
          الجديد؟{" "}
          <motion.span
            initial={{ opacity: 0, scale: 0 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1 }}
            className="text-white sm:leading-[80px] bg-blue-600 rounded-full sm:h-[80px] sm:w-[80px] inline-block w-[50px] h-[50px] leading-[50px]"
          >
            ما
          </motion.span>
        </h2>
      )}
      {lang === "en" && (
        <h2
          dir="ltr"
          className="text-slate-950 sm:text-5xl text-3xl font-bold text-center mb-8"
        >
          What’s Ne
          <motion.span
            initial={{ opacity: 0, scale: 0 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1 }}
            className="text-white sm:leading-[80px] bg-blue-600 rounded-full sm:h-[80px] sm:w-[80px] inline-block w-[50px] h-[50px] leading-[50px]"
          >
            w?
          </motion.span>
        </h2>
      )}
      <p className="text-slate-950 opacity-70 text-xl font-normal text-center sm:px-12 px-0 mb-16">
        {t("new_content")}
      </p>
      <div className="flex flex-wrap justify-between gap-y-4">
        {newData.map((item, index) => (
          <div key={index} className="bg-white lg:w-[32.6%] sm:w-[49%] w-full">
            <Image
              src={item.image}
              alt={`n${index + 1}`}
              className="w-full mb-4"
              style={{
                maxWidth: "100%",
                height: "auto",
              }}
            />
            <h3 className="text-slate-950 text-xl min-h-20 font-bold px-3 mb-4">
              {t(item.title)}{" "}
            </h3>
            <p className="px-3 mb-4 text-slate-950 min-h-48 opacity-70 text-sm font-normal">
              {t(item.description)}
            </p>
            <div className="mb-4 px-3 flex items-center gap-x-2">
              <motion.h4
                whileHover={{ scale: 1.1, transition: { duration: 0.2 } }}
                onClick={() => router.push(`${lang}/articles/${item.title}`)}
                className="text-blue-800 text-base font-semibold cursor-pointer underline"
              >
                {t("show_more")}
              </motion.h4>
              <ChevronRight
                color="#004AAD"
                size={17}
                className="cursor-pointer"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default New;
